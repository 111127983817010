import React from "react";

import * as classes from "./RadioButton.module.css";

const RadioButton = (props) => {
  const radioContainerClass = !props.disabled
    ? `${classes.radio__container}`
    : `${classes.radio__container} ${classes.disabled}`;

  return (
    <label className={radioContainerClass}>
      <div className={classes.radio}>
        <input
          type="radio"
          value={props.value}
          name={props.name}
          onChange={props.onChange}
          checked={props.checked}
          disabled={props.disabled}
        />
        <span className={classes.radio__control}></span>
      </div>
      <span className="radio__label">{props.label}</span>
    </label>
  );
};

export default RadioButton;
