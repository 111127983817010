import React, { useState } from "react";

import RadioButton from "../UI/RadioButton";

import * as classes from "./Faq.module.css";

const Faq = () => {
  const options = ["Shipping", "Care", "Contact"];

  const [extraOptions, setExtraOptions] = useState(options[0]);

  const classOne =
    extraOptions === options[0]
      ? `${classes.moreInfo} ${classes.show}`
      : `${classes.moreInfo}`;

  const classTwo =
    extraOptions === options[1]
      ? `${classes.moreInfo} ${classes.show}`
      : `${classes.moreInfo}`;

  const classThree =
    extraOptions === options[2]
      ? `${classes.moreInfo} ${classes.show}`
      : `${classes.moreInfo}`;

  const handleOptionChange = (e) => {
    setExtraOptions(e.target.value);
  };

  return (
    <div className={classes.mainContainer}>
      <div className={classes.introContainer}>
        <span className={classes.preTitle}>Goriot</span>
        <h1 className={classes.productTitle}>Frequently Asked Questions</h1>
        <h2 className={classes.subTitle}>Shipping</h2>
      </div>
      <div className={classes.subContainer}>
        <div className={classes.buttonContainer}>
          {options.map((o, i) => (
            <RadioButton
              key={i}
              value={o}
              label={o}
              onChange={handleOptionChange}
              checked={extraOptions === o}
              name="extraOptions"
            />
          ))}
        </div>

        <div className={classes.optionsContainer}>
          <div className={classOne}>
            <p>
              <b>Local Shipping</b>
              <br />
              <br />
              Shipping within Australia is a flat rate of $10AUD. We strive to
              process each order within 1-2 business days of purchase, unless
              otherwise stated in checkout. Once your item is shipped, you will
              receive an email with tracking information.
            </p>
            <p>
              <b>International Shipping</b>
              <br />
              <br />
              International shipments are free for orders over $200AUD.
              Otherwise, prices differ depending on zones, from Australia ($20 -
              $25 Standard, $30 - $40 Express) - will be confirmed during
              checkout. Customs charges are different for every country. Goriot
              does not take any responsibility for any taxes, custom charges,
              duties and fees.
            </p>
            <p>
              We do not currently offer returns or exchanges. If you have
              received a faulty, or the wrong sized item, please contact us at
              contact@goriot.com.
            </p>
          </div>

          <div className={classTwo}>
            <p>
              <b>Washing, Size Variance, Stretching</b>
              <br />
              <br />
              Cotton garments stretch with wear and shrink with warm-hot washes.
              We recommend washing at 40ºC or less, on medium setting and to
              hang dry. Please allow for some variance in sizing, due to the
              stretch/shrink nature of the fabric and handmade construction.
              Typically, you can expect the tee to stretch 3-5cm in the
              shoulders and chest. Please take this into account when selecting
              a size.
            </p>
            <p>
              You can enjoy beautiful ageing of the t-shirts through time as it
              adopts a papery-vintage texture with improved drape.
            </p>
            <p>
              The collar is initially snug, however with wear/stretching, a
              perfect fit can be achieved.
            </p>
          </div>

          <div className={classThree}>
            <p>
              <b>Customer Service</b>
              <br />
              <br />
              You can email us at contact@goriot.co with any questions. You may
              expect a response within 2 days. Don’t forget to attach your order
              number and any other relevant information.
            </p>
            <p>
              <b>Business Inquiries</b>
              <br />
              <br />
              For questions regarding wholesale, collaborations and press,
              please email contact@goriot.co.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Faq;
