// extracted by mini-css-extract-plugin
export var mainContainer = "Faq-module--mainContainer--2an9b";
export var introContainer = "Faq-module--introContainer--Oc8jG";
export var preTitle = "Faq-module--preTitle--3OpAK";
export var productTitle = "Faq-module--productTitle--2qOr3";
export var subTitle = "Faq-module--subTitle--1rLaT";
export var moreInfo = "Faq-module--moreInfo--3Knof";
export var subContainer = "Faq-module--subContainer--1jj6H";
export var buttonContainer = "Faq-module--buttonContainer--xdQw-";
export var optionsContainer = "Faq-module--optionsContainer--2LKwT";
export var show = "Faq-module--show--2y9k3";