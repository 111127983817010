import React from "react";
import Seo from "../components/Seo/seo";
import Faq from "../components/Faq/Faq";

const FaqPage = () => {
  return (
    <>
      <Seo title="FAQ" description="FAQ for Goriot" />
      <Faq />
    </>
  );
};

export default FaqPage;
